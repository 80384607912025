import styled from "styled-components";

export const FormControlWrapper = styled.div`
    /* display: flex; */
    /* justify-content: center; */
    /* align-self: flex-end; */
    margin-top: 50px;
    min-width: 20%;
    position: relative;
    @media only screen and (max-width: 350px) {
        margin-top: 20px;
    }
`;
export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

export const FormControls = styled.div`
    max-width: 500px;
    min-width: 100px;
    margin-left: auto;
    margin-right: 170px;
    @media only screen and (max-width: 1400px) {
        margin-right: 0px;
    }
`;
export const ListBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
`;
